<template>
  <div class="device-index">
    <div class="container-header">
      <div class="top-actions-group">
        <div class="action-left">
          <el-form class="geology-form">
            <el-select
                v-model="listfilter.fname"
                clearable
                placeholder="请选择日志类型"
                size="medium"
                @change="onSearch">
                <el-option label="登录日志" value="login"></el-option>
                <el-option label="异常日志" value="Exception"></el-option>
                <el-option label="扩展日志" value="Ext"></el-option>
            </el-select>

            <el-date-picker
              v-model="filterDaterange"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="medium"
              style="width: 240px;margin-left:10px;"
              value-format="yyyy-MM-dd"
              @change="dateChange"
            >
            </el-date-picker>
          </el-form>
        </div>
        <div class="action-right">
          <el-button
            type="warning"
            icon="el-icon-error"
            size="small"
            style="margin-left: 10px"
            :disabled="multipleSelection.length == 0"
            @click="onListDel(-1)"
            >批量删除</el-button
          >
        </div>
      </div>
    </div>

    <el-table
      class="list-table"
      ref="multipleTable"
      :data="listData"
      tooltip-effect="dark"
      style="width: 100%;"
      :stripe="true"
      :border="false"
      v-loading="tableLoading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center">
      </el-table-column>
      <el-table-column label="编号" prop="Id" width="80"></el-table-column>
      <el-table-column label="日志类型" prop="LogType" width="150"></el-table-column>
      <el-table-column label="内容" prop="Description"></el-table-column>
      <el-table-column label="请求地址" prop="UrlAddress"></el-table-column>
      <el-table-column label="时间" prop="CreateTime" width="160">
        <template slot-scope="scope">
          {{ scope.row.CreateTime | datetime }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160">
        <template slot-scope="scope">
          <el-button
            type="success"
            icon="el-icon-view"
            size="mini"
            @click="onShowDetail(scope.row)"
            >详情</el-button
          >
          <el-button
            type="warning"
            icon="el-icon-error"
            size="mini"
            style="margin-left: 10px"
            @click="onListDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      layout="prev, pager, next"
      prev-text="上一页"
      next-text="下一页"
      class="list-pagination"
      :page-size="page.pagesize"
      hide-on-single-page
      :total="page.total"
      :current-page.sync="page.pageindex"
      @current-change="onPageChange"
    >
    </el-pagination>

    <el-dialog
      title="日志详情"
      :visible.sync="dialogDetailVisible"
      width="60%"
      custom-class="geology-dialog"
      :modal-append-to-body="true"
    >
      <el-form ref="form" class="geology-form" label-width="120px" style="margin-top:20px;">
        <el-row>
          <el-col :span="9" :offset="1">
            <el-form-item label="日志类型:">
              {{ activeDetailItem.LogType }}
            </el-form-item>
          </el-col>
          <el-col :span="9" :offset="1">
            <el-form-item label="时间:">
              {{ activeDetailItem.CreateTime | datetime }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="19" :offset="1">
            <el-form-item label="请求地址:">
              {{ activeDetailItem.UrlAddress }}
            </el-form-item>
          </el-col>
          <el-col :span="19" :offset="1">
            <el-form-item label="请求参数:">
              {{ activeDetailItem.UrlPost }}
            </el-form-item>
          </el-col>
        </el-row>

        <el-divider content-position="left">错误内容</el-divider>
        <el-row>
          <el-col :span="19" :offset="1">
            <el-form-item label="描述:">
              {{ activeDetailItem.Description }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="warning" icon="el-icon-edit" size="small"
          >删 除</el-button
        >
        <el-button
          size="small"
          plain
          type="primary"
          @click="dialogDetailVisible = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getSyslogList, delSyslog } from "@/api/system";
export default {
  data() {
    return {
      multipleSelection: [],
      listData: [],
      tableLoading: false,
      filterDaterange: "",
      listfilter: {
        fname: "",
      },
      page: {
        pagesize: 10,
        pageindex: 1,
        total: 0,
      },
      dialogDetailVisible: false,
      activeDetailItem: "",
    };
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    dateChange(value) {
      if (value) {
        var newdate = [];
        for (let index = 0; index < this.filterDaterange.length; index++) {
          const element = this.filterDaterange[index];
          newdate.push(element);
        }
        this.listfilter.fstarttime = newdate[0];
        this.listfilter.fendtime = newdate[1];
        this.getListData();
      } else {
        this.listfilter.fstarttime = "";
        this.listfilter.fendtime = "";
        this.getListData();
      }
    },
    onSearch() {
      this.page.pageindex = 1;
      this.getListData();
    },
    onPageChange(pageindex) {
      this.page.pageindex = pageindex;
      this.getListData();
    },
    getListData() {
      this.tableLoading = true;
      getSyslogList(
        this.page.pageindex,
        this.page.pagesize,
        this.listfilter
      ).then((resdata) => {
        this.tableLoading = false;
        if (resdata.code == 0) {
          this.listData = resdata.data.data;
          this.page.total = resdata.data.count * 1;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
    onListDel(item) {
      this.$confirm("确定要删除选择的数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var ids = "";
          if (item == -1) {
            var idarr = [];
            this.multipleSelection.forEach((item) => {
              idarr.push(item.Id);
            });
            ids = idarr.join(",");
          } else {
            ids = item.Id;
          }
          delSyslog(ids)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("删除成功!");
                this.getListData();
              } else {
                this.$message.error("删除失败!" + res.msg);
              }
            })
            .catch((errmsg) => {
              this.$message.error(errmsg);
            });
        })
        .catch((errmsg) => {
          this.$message.error(errmsg);
        });
    },
    onShowDetail(item) {
      this.activeDetailItem = item;
      this.dialogDetailVisible = true;
    },
  },
  created() {
    this.getListData();
  },
};
</script>